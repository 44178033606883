import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { closePopup, openPopup } from '../../actions/popup';
import { AppState } from '../../store';
import { AuthState, Nomination } from '../../types/store';
import s from './Popup.module.scss';

interface Props {
    auth: AuthState,
    nomination: Nomination | null,
    isOpened: boolean,
    closePopup: () => void,
};

const Popup = ({ auth: { isAuth }, nomination, isOpened, closePopup }: Props) => {

    const closeEvent = () => {
        closePopup();
    }



    return (
        <>
            {(isOpened && nomination) &&
                <div className={s.popup}>
                    <div className={s.popup__table}>
                        <button className={s.popup__close} onClick={() => closeEvent()}>
                            <svg width="16" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1.41062" y1="1.89924" x2="14.8992" y2="13.5894" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                <line x1="15" y1="1.41421" x2="1.41421" y2="15" stroke="black" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </button>
                        <div className={s.popup__header}>
                            <div className={s.popup__header_title}>
                                <div className={s.popup__title}>{nomination.nominationName}</div>
                                <div className={s.popup__number}>{(isAuth && nomination.userScore && nomination.userScore[0]) && `#${nomination.userScore[0]}`}</div>
                            </div>
                            <div className={s.popup__columns}>
                                {nomination.columnNames.map((name: string) => {
                                    return (
                                        <div key={name}>{name}</div>)
                                })}
                            </div>
                        </div>
                        <div className={s.popup__body}>
                            <div className={s.popup__body_canvas}>
                                {
                                    nomination.table.map(([place, vk, name, ...rest]: string[]) => (
                                        <div key={vk}>
                                            <div>{place}</div>
                                            <div>
                                                <Link to={`/${nomination.isTeamNomination ? "team" : "profile"}/${vk}`} onClick={ () => closeEvent() }>{name}</Link>
                                            </div>
                                            {rest.map((item, i) => <div key={i}>{item}</div>)}
                                        </div>
                                    )
                                    )
                                }

                            </div>
                        </div>
                        <div className={s.popup__footer}>
                            <div className={s.popup__footer_canvas}>
                                {!!nomination.userScore?.length &&
                                    <div>
                                        {nomination.userScore.map((item, i) => <div key={i}>{item}</div>)}
                                    </div>
                                }
                                <button onClick={() => closeEvent()}>Закрыть список</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    auth: state.auth,
    nomination: state.nominations.Nomination,
    isOpened: state.popup.isOpened
})

export default connect(mapStateToProps, { closePopup })(Popup);