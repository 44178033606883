import { ProfileActionTypes } from '../types/actions';
import { ProfileState } from '../types/store';
import {
    LOADING_PROFILE,
    GET_PROFILE
} from '../types/actions';

const initialState: ProfileState = {
    isLoading: false,
    data: []
};

export default function TeamsReducer(state = initialState, action: ProfileActionTypes): ProfileState {
    switch (action.type) {
        case LOADING_PROFILE:
            return {
                ...state,
                isLoading: true
            };
        case GET_PROFILE:
            return {
                isLoading: false,
                data: {
                    ...action.profile
                }
            };
        default:
            return state;
    }
};