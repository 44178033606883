import React, { useEffect } from "react";
import { AppState } from '../../store';
import { connect } from 'react-redux';

import { getQuestions } from "../../actions/questions";
import { QuestionsState } from "../../types/store";

import Question from '../../components/Question/Question';

import s from './Faq.module.scss';

interface PropsFromState {
    questions: QuestionsState
};

interface PropsFromDispatch {
    getQuestions: () => void
};

type Props = PropsFromState & PropsFromDispatch;

const Faq = ({ questions, getQuestions }: Props) => {

    useEffect(() => {
        getQuestions();
    }, []);

    return (
            <div className="container">
              <div className={s.wrapper}>
              <div className={s.title}>
                #FAQ
              </div>
                <div className={s.faq}>
                    {!questions.isLoading && questions.data.map(question => 
                        <Question key={question.faqId} question={question.question}  answer={question.answer} />
                    )}
                </div>
                <img className={s.footerImg} src="/static/images/faq_image.svg" alt=""/>
              </div>
            </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    questions: state.questions
});

export default connect(mapStateToProps, { getQuestions })(Faq);