import { PopupActionTypes } from '../types/actions';
import { PopupState } from '../types/store';
import {
    OPEN_POPUP,
    CLOSE_POPUP
} from '../types/actions';

const initialState: PopupState = {
    isOpened: false
};

export default function QuestionsReducer(state = initialState, action: PopupActionTypes): PopupState {
    switch (action.type) {
        case OPEN_POPUP:
            return {
                isOpened: true
            };
        case CLOSE_POPUP:
            return {
                isOpened: false
            };
        default:
            return state;
    }
};