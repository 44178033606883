import s from "./TeamTable.module.css";
import React from "react";
import {Nomination, Team} from '../../types/store';
import Best from "../Best/Best";


const TeamTable = (props:any) => {
  const {teamId,teamName,description,teammates}:Team = props.teamData
  return(
    <div className={s.wrapper}>
      <div className={s.titleWrapper}>
          <p className={s.title}>
            <span>#Команда</span>
            <img className={s.img} src="/static/images/teamTitleImg.png" alt=""/>
          </p>
      </div>
      <div className={s.body}>
        <div className={s.teamAbout}>
          <div className={s.team}>
            <img className={s.teamImg} src="/static/images/teamNoLogo.jpg" alt=""/>
            <p className={s.teamName}>{teamName}</p>
          </div>
            <p className={s.teamDescr}>{description}</p>
        </div>
      </div>
      <div className={s.teammates}>
        {
          teammates.map(teammate=>(
            <div key={teammate['username']} className={s.teammate}>
              <img src={teammate['image'] ? teammate['image'] : '/static/images/teamNoLogo.jpg' } alt="teammate logo"/>
              <p className={s.teammateName}>{teammate['username']}</p>
              <a href={teammate['buff']} className={s.teammateBuff}>dotabuff</a>
            </div>
          ))
        }
      </div>
      {
        !!props.nominations.length &&
          <div className={s.tableContainer}>
            { props.nominations.map((nomination: Nomination) => <Best key={ nomination.tableName } title={ nomination.nominationName } tableData={ nomination } />) }
          </div>
        }

    </div>
  )
}

export default TeamTable