import s from './Ratings.module.css';

const Ratings = (props:any) => {
    return (
        <div className={s.wrapper}>
            <div className={s.body}>
                <div>
                    <div className={s.name}>{props.team.teamName}</div>
                    <div>{props.team.winsCount}</div>
                    <div>{props.team.stack}</div>
                    <div>{props.team.rating}</div>
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>
    );
}

export default Ratings;