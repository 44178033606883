import { GET_NOMINATION, NominationsActionTypes } from '../types/actions';
import { NominationsState } from '../types/store';
import {
    LOADING_NOMINATIONS,
    GET_NOMINATIONS,
    GET_USER_NOMINATIONS
} from '../types/actions';

const initialState: NominationsState = {
    isLoading: false,
    Nomination: null,
    Nominations: [],
    UserNominations: []

};

export default function QuestionsReducer(state = initialState, action: NominationsActionTypes): NominationsState {
    switch (action.type) {
        case LOADING_NOMINATIONS:
            return {
                ...state,
                isLoading: true
            };
        case GET_NOMINATION:
            return {
                ...state,
                isLoading: false,
                Nomination: {
                    ...action.nomination,
                    columnNames: action.nomination.columnNames.filter(name => name !== "Вк"),
                    userScore: action.nomination.userScore?.filter((_, i) => i !== 1)
                }
            }
        case GET_NOMINATIONS:
            return {
                ...state,
                isLoading: false,
                Nominations: action.nominations.map(nomination => ({
                    ...nomination,
                    columnNames: nomination.columnNames.filter(name => name !== "Вк"),
                    userScore: nomination.userScore?.filter((_, i) => i !== 1)
                }))
            };
        case GET_USER_NOMINATIONS:
            return {
                ...state,
                isLoading: false,
                UserNominations: action.userNominations.map(nomination => ({
                    ...nomination,
                    columnNames: nomination.columnNames.filter(name => name !== "Вк"),
                    userScore: nomination.userScore?.filter((_, i) => i !== 1)
                }))
            };
        default:
            return state;
    }
};