import api from '../utils/api';
import { AppDispatch, AppThunk } from "../store";
import { GET_PROFILE, LOADING_PROFILE } from "../types/actions";

const getProfile = (id: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: LOADING_PROFILE
    });
    const response = await api.get(`Users/Get/${ id }`); // исправить
    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_PROFILE,
            profile: result.data
        })
    };
};

export {
    getProfile
};