import React from "react";
import s from './Table.module.scss';

const Table = (props: any) => {
    return (
        <div className={s.wrapper}>
            <div className={s.title}>
                <p dangerouslySetInnerHTML={{__html:props.title}}/>
            </div>
            <div className={s.body}>
                {props.children}
            </div>
        </div>
    );
}

export default Table;