import { Question, Nomination, Team, Pairs, Profile, UserNomination, TournamentTop } from './store';

export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

export const LOADING_QUESTIONS = 'LOADING_QUESTIONS';
export const GET_QUESTIONS = 'GET_QUESTIONS';

export const GET_NOMINATION = 'GET_NOMINATION'
export const LOADING_NOMINATIONS = 'LOADING_NOMINATIONS';
export const GET_NOMINATIONS = 'GET_NOMINATIONS';
export const GET_USER_NOMINATIONS = 'GET_USER_NOMINATIONS';

export const LOADING_TEAMS = 'LOADING_TEAMS';
export const GET_TEAMS = 'GET_TEAMS';
export const GET_TOURNAMENT_TOP = 'GET_TOURNAMENT_TOP'

export const LOADING_PROFILE = 'LOADING_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';

export const LOADING_PAIRS = 'LOADING_PAIRS';
export const GET_PAIRS = 'GET_PAIRS';

export const OPEN_POPUP = 'OPEN_POPUP'
export const CLOSE_POPUP = 'CLOSE_POPUP'

interface OpenPopupAction {
    type: typeof OPEN_POPUP,
    
}
interface ClosePopupAction {
    type: typeof CLOSE_POPUP,

}

interface LoginAction {
    type: typeof LOGIN,
    vk_id: number,
    name: string
}

interface LogoutAction {
    type: typeof LOGOUT
}

interface LoadingQuestionsAction {
    type: typeof LOADING_QUESTIONS
};

interface LoadingTeamsAction {
    type: typeof LOADING_TEAMS
};

interface GetTeamsAction {
    type: typeof GET_TEAMS,
    teams: Team[]
};

interface getTournamentTopAction {
    type: typeof GET_TOURNAMENT_TOP,
    tournamentTops: TournamentTop[]
}

interface GetQuestionsAction {
    type: typeof GET_QUESTIONS,
    questions: Question[]
};

interface LoadingNominationsAction {
    type: typeof LOADING_NOMINATIONS
};

interface GetNominationAction {
    type: typeof GET_NOMINATION,
    nomination: Nomination
}

interface GetNominationsAction {
    type: typeof GET_NOMINATIONS,
    nominations: Nomination[]
};

interface GetUserNominationsAction {
    type: typeof GET_USER_NOMINATIONS,
    userNominations: UserNomination[]
};


interface LoadingPairsAction {
    type: typeof LOADING_PAIRS
};

interface GetPairsAction {
    type: typeof GET_PAIRS,
    pairs: Pairs[]
};

interface LoadingProfileAction {
    type: typeof LOADING_PROFILE
};

interface GetProfileAction {
    type: typeof GET_PROFILE,
    profile: Profile[]
};

export type AuthActionTypes = LoginAction | LogoutAction;
export type QuestionsActionTypes = LoadingQuestionsAction | GetQuestionsAction;
export type TeamsActionTypes = LoadingTeamsAction | GetTeamsAction | getTournamentTopAction;
export type NominationsActionTypes = LoadingNominationsAction | GetNominationAction | GetNominationsAction | GetUserNominationsAction;
export type PairsActionTypes = LoadingPairsAction | GetPairsAction;
export type ProfileActionTypes = LoadingProfileAction | GetProfileAction;
export type PopupActionTypes = OpenPopupAction | ClosePopupAction;