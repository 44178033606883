import React from "react";
import Ratings from "../Ratings/Ratings";
import s from './Pairs.module.css';


const Pair = (props: any) => {
  console.log(props.pairs.data)
  return (
    <div className={s.wrapper}>
      <h2>Пары команд:</h2>
      <div className={s.ratingHeader}>

        <div className={s.columns}>
          <div></div>
          <div>Победы:</div>
          <div>Размер стэка:</div>
          <div>Рейтинг:</div>
          <div>&nbsp;</div>
        </div>

        <div className={s.between}/>

        <div className={s.columns}>
          <div></div>
          <div>Победы:</div>
          <div>Размер стэка:</div>
          <div>Рейтинг:</div>
          <div className={s.empty}>&nbsp;</div>
        </div>

      </div>
      {
        props.pairs.data.map((pairData:any)=> {
          return(
            <div className={s.body}>
              <Ratings team={pairData.teams[0]}/>
              <div className={s.middle}>
                <div>
                  <div className={s.versus}>VS</div>
                  <div className={s.date}>{
                    pairData.date.split('T')[0].replaceAll('-','.')
                  }</div>
                </div>
              </div>
              <Ratings team={pairData.teams[1]}/>
              <div className={s.dateMob}>{
                pairData.date.split('T')[0].replaceAll('-','.')
              }</div>
            </div>
          )
        })
      }
    </div>
  );
}

export default Pair;