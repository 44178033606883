import { PairsActionTypes } from '../types/actions';
import { PairsState } from '../types/store';
import {
    LOADING_PAIRS,
    GET_PAIRS
} from '../types/actions';

const initialState: PairsState = {
    isLoading: false,
    data: []
};

export default function QuestionsReducer(state = initialState, action: PairsActionTypes): PairsState {
    switch (action.type) {
        case LOADING_PAIRS:
            return {
                ...state,
                isLoading: true
            };
        case GET_PAIRS:
            return {
                isLoading: false,
                data: [
                    ...action.pairs
                ]
            };
        default:
            return state;
    }
};