import Pairs from "../../components/Pairs/Pairs";
import Top from "../../components/Top/Top";
import Table from "../../components/Table/Table";
import React, {useEffect} from "react";
import {AppState} from "../../store";
import {connect} from "react-redux";
import {getPairs} from "../../actions/pairs";
import {PairsState, TeamState} from "../../types/store";

import s from './Match.module.scss'
import { getTournamentTop } from "../../actions/teams";

interface PropsFromState {
  pairs: PairsState,
  teams: TeamState
};

interface PropsFromDispatch {
  getPairs: () => void,
  getTournamentTop: () => void
};

type Props = PropsFromState & PropsFromDispatch;

const Match = ({teams, pairs, getPairs, getTournamentTop}: Props) => {
  useEffect(() => {
    getPairs();
    getTournamentTop();
  }, []);
  return (
    <div className="container">
      <p className={s.title}>#Текущие <br/> матчи</p>
      <div className={s.wrapper}>
        <Pairs pairs={pairs}/>
        <Top teams={ teams.tournamentTops }/>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  pairs: state.pairs,
  teams: state.teams
});

export default connect(mapStateToProps, {getPairs, getTournamentTop})(Match);
