import { combineReducers } from 'redux'

import questions from './questions';
import nominations from './nominations';
import teams from './teams';
import pairs from './pairs';
import profile from './profile'
import auth from './auth'
import popup from './popup'

const rootReducer = combineReducers({ 
    questions, nominations,teams, pairs, profile, auth, popup
});

export default rootReducer;