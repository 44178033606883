import React, {useEffect} from 'react';
import { AppState } from '../../store';
import { connect } from 'react-redux';

import ProfileTable from "../../components/ProfileTable/ProfileTable";
import {AuthState, NominationsState, ProfileState} from "../../types/store";

import {getNominations, getUserNominations} from '../../actions/nominations';
import {getProfile} from "../../actions/profile";
import { RouteComponentProps } from 'react-router';



interface PropsFromState {
  auth: AuthState
  profile: ProfileState
  nominations: NominationsState
};

interface PropsFromDispatch {
  getProfile: (id: string) => void
  getUserNominations: (vk_id:number) => void
};

type Props = PropsFromState & PropsFromDispatch & RouteComponentProps;


const Profile = ({ auth, profile, getProfile, nominations, getUserNominations}: Props) => {
  useEffect(() => {

    getProfile(auth.vk_id.toString());
    getUserNominations(auth.vk_id)
  }, []);
  return (
    <div className="container">
      { !Object.keys(profile.data).length && <div style={ {color: 'black', textAlign: 'center', fontSize: '32px', marginTop: '100px'} }>Игрок не найден</div> } 
      {
        
        !!Object.keys(profile.data).length &&
        <>
          <ProfileTable nominations={nominations.UserNominations} profileData={profile.data}/>
        </>
      }
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
  profile: state.profile,
  nominations: state.nominations
});

export default connect(mapStateToProps, {getProfile,getUserNominations })(Profile);