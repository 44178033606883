import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from './store';

import App from './App';

import './index.css';
import { LOGIN } from './types/actions';

(() => {
    const sessionString = localStorage.getItem("vk_session");
    if (sessionString) {
        const session = JSON.parse(sessionString);
        store.dispatch({ type: LOGIN, ...session });
    }
})()

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>, 
    document.getElementById('root')
);