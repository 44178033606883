import api from '../utils/api';
import { AppDispatch, AppThunk } from "../store";
import { GET_QUESTIONS, LOADING_QUESTIONS } from "../types/actions";

const getQuestions = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({ 
        type: LOADING_QUESTIONS 
    });
    const response = await api.get(`Faqs/GetAll`);
    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_QUESTIONS,
            questions: result.data
        })
    };
};

export {
    getQuestions
};