import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Faq from './pages/Faq/Faq';
import Team from "./pages/Team/Team";
import Contacts from "./pages/Contacts/Contacts";
import Match from "./pages/Match/Match";
import Profile from "./pages/Profile/Profile";
import UserProfile from './pages/Profile/UserProfile';
import PrivateRouter from './components/PrivateRouter/PrivateRouter';
import UserTeam from './pages/Team/UserTeam';
import Popup from './components/Popup/Popup';
import Registration from './pages/Registration/Registration';
import Default from './pages/404';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PZGGQWZ'
}

TagManager.initialize(tagManagerArgs)

export default function App () {
    document.title = 'Турниры DOTA 2 SIVVIT';
    return (
        <div className="app">
            <Header />
            <Switch>
                <Route exact path="/" component={ Home } />
                <Route path="/Faq" component={ Faq } />
                <Route path="/contacts" component={ Contacts } />
                <Route path="/match" component={ Match } />
                <Route path="/profile/:id" component={ UserProfile } />
                <Route path="/team/:id" component={ UserTeam } />
                <PrivateRouter path="/profile" component={ Profile } />
                <PrivateRouter path="/team" component={ Team } />
                <Route path="/registration" component={ Registration } />
                <Route component={ Default } />
            </Switch>
            <Popup />
        </div>
    );
};