import React from "react";
import { TournamentTop } from "../../types/store";
import s from "../Top/Top.module.scss";

interface PropsFromComponent {
    teams: TournamentTop[]
}

const Top = ({ teams }: PropsFromComponent) => {
    return (
        <div className={s.wrapper}>
            <h2>Топ турнира:</h2>
            <div className={s.body}>
                <div>
                    <div></div>
                    <div>Победы:</div>
                    <div>Кол-во игр:</div>
                    <div>Размер стэка:</div>
                    <div>Рейтинг:</div>
                </div>
                <div>
                    <div></div>
                    <div>Победы:</div>
                    <div>Кол-во игр:</div>
                    <div>Размер стэка:</div>
                    <div>Рейтинг:</div>
                </div>
                {teams.map((team, i) => <div key={ team.teamId }>
                    <div><div className={s.number}>#{i + 1}</div>{ team.teamName }</div>
                    <div>{ team.winsCount }</div>
                    <div>{ team.gameCount }</div>
                    <div>{ team.stack }</div>
                    <div>{ team.rating }</div>
                </div>)}
            </div>
        </div>
    );
}

export default Top;