import { AppDispatch, AppThunk } from "../store";
import { CLOSE_POPUP, OPEN_POPUP } from "../types/actions";

const openPopup = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: OPEN_POPUP
    });
};

const closePopup = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: CLOSE_POPUP
    });
};

export {
    closePopup, openPopup
};