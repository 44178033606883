import React, {useEffect} from 'react';
import { AppState } from '../../store';
import { connect } from 'react-redux';

import TeamTable from "../../components/TeamTable/TeamTable";
import {AuthState, NominationsState, TeamState} from "../../types/store";

import {getTeamNominations} from '../../actions/nominations';
import {getTeams} from "../../actions/teams";



interface PropsFromState {
  auth: AuthState
  teams: TeamState
  nominations: NominationsState
};

interface PropsFromDispatch {
  getTeams: (id: number) => void
  getTeamNominations: (teamId:number) => void
};

type Props = PropsFromState & PropsFromDispatch;


const Team = ({auth, teams, getTeams, nominations, getTeamNominations}: Props) => {
  useEffect(() => {
    getTeams(auth.vk_id);
    getTeamNominations(auth.vk_id)

  }, []);
  return (
    <div className="container">
      { !Object.keys(teams.teams).length && <div style={ {color: 'black', textAlign: 'center', fontSize: '32px', marginTop: '100px'} }>Вы пока не состоите в команде</div> }
      { !!Object.keys(teams.teams).length && <TeamTable nominations={nominations.UserNominations} teamData={teams.teams}/> }
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
  teams: state.teams,
  nominations: state.nominations
});

export default connect(mapStateToProps, {getTeams,getTeamNominations })(Team);