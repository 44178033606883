import { QuestionsActionTypes } from '../types/actions';
import { QuestionsState } from '../types/store';
import {
    LOADING_QUESTIONS,
    GET_QUESTIONS
} from '../types/actions';

const initialState: QuestionsState = {
    isLoading: false,
    data: []
};

export default function QuestionsReducer(state = initialState, action: QuestionsActionTypes): QuestionsState {
    switch (action.type) {
        case LOADING_QUESTIONS:
            return {
                ...state,
                isLoading: true
            };
        case GET_QUESTIONS:
            return {
                isLoading: false,
                data: [
                    ...action.questions
                ]
            };
        default:
            return state;
    }
};