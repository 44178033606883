import api from '../utils/api';
import { AppDispatch, AppThunk } from "../store";
import { LOGIN, LOGOUT } from "../types/actions";

const login = (): AppThunk => async (dispatch: AppDispatch) => {
    VK.Auth.login(response => {
        if (response.session) {
            const user = {
                vk_id: Number(response.session.user.id),
                name: `${response.session.user.last_name} ${response.session.user.first_name}`
            }

            localStorage.setItem('vk_session', JSON.stringify(user));

            window.location.reload(); // это вынужденная мера...
        }
    }, 0);
};


const logout = (): AppThunk => async (dispatch: AppDispatch) => {
    VK.Auth.logout(() => {});
    localStorage.removeItem('vk_session');
    window.location.reload(); // это вынужденная мера...
};


export {
    login, logout
};