import s from './Contacts.module.css'

const Contacts = () => {
  return (
    <div className={s.container}>
      <p className={s.title}>#Контакты</p>
      <div className={s.contacts}>
        <a target="_blank" className={s.social} href="https://www.youtube.com/channel/UC1pLLM8h49vYoJORQhuL7sA">
          <img className={s.image} src="/static/images/icons/youtube_icon.svg" alt=""/>
          <p className={s.text}>/channel/sivvit</p>
        </a>
        <a className={s.social} href="mailto:sivvit@sivvit.ru">
          <img className={s.image} src="/static/images/icons/email_icon.svg" alt=""/>
          <p className={s.text}>sivvit@sivvit.ru</p>
        </a>
        <a target="_blank" className={s.social} href="https://vk.com/dota_sivvit">
          <img className={s.image} src="/static/images/icons/vk_icon.svg" alt=""/>
          <p className={s.text}>/dota_sivvit</p>
        </a>
      </div>
      <img className={s.footerImage} src="/static/images/contacts_img.png" alt="..."/>
    </div>
  )
}

export default Contacts