import React, { useState } from "react";

import s from "./Registration.module.scss";

const Registration = () => {
    const [tab, setTab] = useState(false);
    return (
        <div className="container">
            <div className={s.wrapper}>
                <div className={s.background}>#Регистрация</div>
                <div className={s.content}>
                    <div className={s.block}>
                        <div className={s.header}>
                            <div className={s.title}>Регистрация на турнир</div>
                            <img src="/static/images/reg.png" alt="" />
                        </div>
                        <div className={s.body}>
                            <div className={s.navigation}>
                                <button className={`${!tab && s.active}`} onClick={ () => setTab(false) }>Как зарегистрировать команду</button>
                                <button className={`${tab && s.active} ${s.margin}`} onClick={ () => setTab(true) }>Как зарегистрировать игрока</button>
                            </div>

                            <div className={s.textarea}>
                                { tab ? 
                                    <p>— В разработке —</p>
                                  :
                                  <p>
                                      РЕГИСТРАЦИЯ ЧЕРЕЗ СООБЩЕНИЯ СООБЩЕСТВА.<br/>
                                    https://vk.me/dota_sivvit (для старта отправьте команду Начать)<br/>
                                    Заявки на участие принимает бот.<br/>

                                    Регистрация идет до 13:00 воскресенья, заявки оставленные позже, принимаются по усмотрению администратора.<br/>
                                    Регистрация проводится до набора 16 команд.<br/>

                                    Админ публикует названия зарегистрированных команд за день до турнира.<br/>
                                    Можно иметь запасных игроков (3 человека).<br/>

                                    Призовой фонд установлен в размере 1000 рублей (на команду) или 2000 для донов.<br/>
                                    1 Место: 1000 Рублей или 2000 для донов.<br/>

                                    Начало турнира в воскресенье 15:00 мск<br/>
                                    Не явка команды после старта в течение 15 минут - вылет с турнира<br/>


                                    Расписание игр (время 2-4 игры ориентировочное):<br/>

                                    1 игра турнира 15:00 bo1 1/8<br/>

                                    2 игра турнира 16:00 bo1 1/4<br/>

                                    3 игра турнира 17:00 bo1 1/2<br/>

                                    4 игра турнира 18:00 bo1 final<br/>


                                    Обсуждения и вопросы сюда - http://vk.com/topic-85969739_31597143<br/>
                                    Правила турнира и информация: https://vk.com/topic-85969739_35816870<br/>
                                    Поиск игроков/команды: http://vk.com/topic-85969739_31597171<br/>

                                    Данные ответственного за данный турнир:<br/>
                                    vk: Сысоев Иван ( http://vk.com/sivvit )<br/>

                                    *команды с аудиторией свыше 10к принимаются на усмотрение администратора (без учета очереди и репостов)
                                  </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;