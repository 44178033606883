import { AuthActionTypes } from '../types/actions';
import { AuthState } from '../types/store';
import {
    LOGIN,
    LOGOUT
} from '../types/actions';

const initialState: AuthState = {
    isAuth: false,
    vk_id: 0,
    name: ""
};

export default function QuestionsReducer(state = initialState, action: AuthActionTypes): AuthState {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuth: true,
                vk_id: action.vk_id,
                name: action.name
            };
        case LOGOUT:
            return {
                ...state,
                isAuth: false,
                vk_id: 0
            };
        default:
            return state;
    }
};