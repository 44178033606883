import api from '../utils/api';
import { AppDispatch, AppThunk } from "../store";
import { GET_NOMINATIONS, LOADING_NOMINATIONS, GET_USER_NOMINATIONS, GET_NOMINATION } from "../types/actions";

const getNomination = (id: Number, vk_id?: Number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: LOADING_NOMINATIONS
    });
    const response = await api.get(`Nominations/Get/${id}?&${vk_id ? "vk_id=" + vk_id : ""}&LineCount=0&amount=1000`);
    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_NOMINATION,
            nomination: result.data
        })
    };
};

const getNominations = (vk_id?: Number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: LOADING_NOMINATIONS
    });
    const response = await api.get(`Nominations/GetAll?${vk_id ? "vk_id=" + vk_id : ""}`);
    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_NOMINATIONS,
            nominations: result.data
        })
    };
};

const getUserNominations = (userId: Number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: LOADING_NOMINATIONS
    });
    const response = await api.get(`Nominations/GetAll?vk_id=${userId}&OnlyUserScore=true`);
    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_USER_NOMINATIONS,
            userNominations: result.data
        })
    };
};

const getTeamNominations = (teamId: Number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: LOADING_NOMINATIONS
    });
    const response = await api.get(`Nominations/GetAll?vk_id=${teamId}&OnlyUserScore=true&OnlyTeamNominations=true`);
    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_USER_NOMINATIONS,
            userNominations: result.data
        })
    };
};


export {
    getNomination,
    getNominations,
    getUserNominations,
    getTeamNominations
};