import React, { useEffect } from "react";
import { AppState } from '../../store';
import { connect } from 'react-redux';

import s from './default.module.css';

const Faq = () => {

    return (
            <div className="container">
              <div className={s.wrapper}>
              <div className={s.title}>
                #404#404#404#404#404#404#404#404#404#404#404#404#404#404#404#404#404#404
              </div>
                <div className={s.textErr}>
                    <p className={s.value}>ошибка<br></br>404</p>
                    <span className={s.desc}>страница не найдена</span>
                </div>
                <img className={s.footerImg} src="/static/images/krys404.png" alt=""/>
              </div>
            </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    questions: state.questions
});

export default Faq;