import React, {useEffect} from 'react';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'

import ProfileTable from "../../components/ProfileTable/ProfileTable";
import {AuthState, NominationsState, ProfileState} from "../../types/store";

import {getUserNominations} from '../../actions/nominations';
import {getProfile} from "../../actions/profile";



interface PropsFromState {
  auth: AuthState
  profile: ProfileState
  nominations: NominationsState
};

interface PropsFromDispatch {
  getProfile: (id: string) => void
  getUserNominations: (userId:number) => void
};

type Props = PropsFromState & PropsFromDispatch;


const UserProfile = ({ auth, profile, getProfile, nominations, getUserNominations}: Props) => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    getProfile(id);
    getUserNominations(Number(id));
  }, []);
  return (
    <div className="container">
      { !Object.keys(profile.data).length && <div style={ {color: 'black', textAlign: 'center', fontSize: '32px', marginTop: '100px'} }>Игрок не найден</div> } 
      {
        !!Object.keys(profile.data).length &&
        <>
          <ProfileTable nominations={nominations.UserNominations} profileData={profile.data}/>
        </>
      }
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
  profile: state.profile,
  nominations: state.nominations
});

export default connect(mapStateToProps, {getProfile,getUserNominations })(UserProfile);