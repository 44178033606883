import React, {useEffect} from 'react';
import { AppState } from '../../store';
import { connect } from 'react-redux';

import TeamTable from "../../components/TeamTable/TeamTable";
import {AuthState, NominationsState, TeamState} from "../../types/store";

import {getNominations, getTeamNominations} from '../../actions/nominations';
import {getTeams} from "../../actions/teams";
import Best from "../../components/Best/Best";
import { useParams } from 'react-router-dom';



interface PropsFromState {
  auth: AuthState
  teams: TeamState
  nominations: NominationsState
};

interface PropsFromDispatch {
  getTeams: (id: number) => void
  getTeamNominations: (userId:number) => void
};

type Props = PropsFromState & PropsFromDispatch;


const Team = ({auth, teams, getTeams, nominations, getTeamNominations}: Props) => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    getTeams(Number(id));
    getTeamNominations(Number(id))

  }, []);
  return (
    <div className="container">
      { !Object.keys(teams.teams).length && <div style={ {color: 'black', textAlign: 'center', fontSize: '32px', marginTop: '100px'} }>Команда не найдена</div> }
      { !!Object.keys(teams.teams).length && <TeamTable nominations={nominations.UserNominations} teamData={teams.teams}/> }
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
  teams: state.teams,
  nominations: state.nominations
});

export default connect(mapStateToProps, {getTeams,getTeamNominations })(Team);