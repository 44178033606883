import React, { useState } from "react";

import s from './Question.module.scss';

interface Props {
    question: string,
    answer: string
};

const Question = ({ question, answer }: Props) => {
    const [isOpened, setIsOpened] = useState(false);

    const toggleIsOpened = () => {
        setIsOpened(prev => !prev);
    };

    return (
        <div className={s.card}>
            <div className={s.header} onClick={() => toggleIsOpened()}>
                <div className={s.question}>{ question }</div>
                {/* <img className={!isOpened ? s.arrow : s.arrow} src="/static/images/icons/arrow.svg" alt="" /> */}
                <img className={`
                    ${s.arrow}
                    ${isOpened ? s.open : ''}
                `} src="/static/images/icons/arrow.svg" alt="" />
            </div>
            {isOpened && <div className={s.body}>
                <div className={s.answer}>{ answer }</div>
            </div>}
        </div>
    );
};

export default Question;