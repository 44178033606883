import api from '../utils/api';
import { AppDispatch, AppThunk } from "../store";
import { GET_PAIRS, LOADING_PAIRS } from "../types/actions";

const getPairs = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({
        type: LOADING_PAIRS
    });
    const response = await api.get(`Pairs/GetAll`);
    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_PAIRS,
            pairs: result.data
        })
    };
};

export {
    getPairs
};