import s from "./ProfileTable.module.scss";
import React from "react";
import {Nomination, Profile} from '../../types/store';
import Best from "../Best/Best";


const ProfileTable = (props:any) => {
  const {vkId,username, description,buff} :Profile = props.profileData
  return(
    <div className={s.wrapper}>
      <div className={s.titleWrapper}>
          <p className={s.title}>
            <span>#Профиль</span>
            <img className={s.img} src="/static/images/teamTitleImg.png" alt=""/>
          </p>
      </div>
      <div className={s.body}>
        <div className={s.teamAbout}>
          <div className={s.team}>
            <img className={s.teamImg} src="/static/images/teamNoLogo.jpg" alt=""/>
            <p className={s.teamName}>{username}</p>
          </div>
            <p className={s.teamDescr}>{ description || "Пустое описание" }</p>
        </div>
      </div>
      {
        !!props.nominations.length &&
          <div className={s.tableContainer}>
            { props.nominations.map((nomination: Nomination) => <Best key={ nomination.tableName } title={ nomination.nominationName } tableData={ nomination } />) }
          </div>
        }

    </div>
  )
}

export default ProfileTable