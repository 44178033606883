import React, { useEffect } from 'react';
import { AppState } from '../../store';
import { connect } from 'react-redux';

import { getNominations } from '../../actions/nominations';
import { AuthState, Nomination, NominationsState } from '../../types/store';
import Best from "../../components/Best/Best";
import Table from "../../components/Table/Table";

import s from './Home.module.css';


interface PropsFromState {
  nominations: NominationsState,
  auth: AuthState
};

interface PropsFromDispatch {
  getNominations: (vk_id?:number) => void
};

type Props = PropsFromState & PropsFromDispatch;

const Home = ({ nominations: { Nominations }, auth, getNominations }: Props) => {
  useEffect(() => {
    getNominations(auth.vk_id);
  }, []);

  return (
    <div className="container">
      <Table title={'#Текущие'}>
        {
          Nominations.map((nomination: Nomination) => <Best key={ nomination.tableName } title={ nomination.nominationName } tableData={ nomination } />)
        }
      </Table>
      <figure className={s.rat}>
        <img src='/static/images/krysCur.png'/>
      </figure>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  nominations: state.nominations,
  auth: state.auth
});

export default connect(mapStateToProps, { getNominations })(Home);