import { GET_TOURNAMENT_TOP, TeamsActionTypes } from '../types/actions';
import { TeamState } from '../types/store';
import {
    LOADING_TEAMS,
    GET_TEAMS
} from '../types/actions';

const initialState: TeamState = {
    isLoading: false,
    teams: [],
    tournamentTops: []
};

export default function TeamsReducer(state = initialState, action: TeamsActionTypes): TeamState {
    switch (action.type) {
        case LOADING_TEAMS:
            return {
                ...state,
                isLoading: true
            };
        case GET_TEAMS:
            return {
                ...state,
                isLoading: false,
                teams: action.teams
            };
        case GET_TOURNAMENT_TOP:
            return {
                ...state,
                isLoading: false,
                tournamentTops: action.tournamentTops
            };
        default:
            return state;
    }
};