import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getNomination } from "../../actions/nominations";
import { openPopup } from "../../actions/popup";
import { AppState } from "../../store";
import { AuthState, Nomination } from "../../types/store";
import Popup from "../Popup/Popup";
import s from './Best.module.scss';

interface PropsFromComponent {
  title: string,
  tableData: Nomination
}

interface PropsFromDispatch {
  getNomination: (id: Number, vk_id?: Number) => void,
  openPopup: () => void
}

interface PropsFromState {
  auth: AuthState
}

type Props = PropsFromComponent & PropsFromState & PropsFromDispatch;

const Best = ({ title, tableData, auth: { isAuth, vk_id }, getNomination, openPopup }: Props) => {
  const [tipsOpened, setTipsOpened] = useState(false);

  const toggleTips = () => {
    setTipsOpened(prevstate => !prevstate)
  }

  const showPopup = (id: number) => {
    getNomination(id, vk_id);
    openPopup();
  }

  //!!tableData.table.length &&
  return (
    <div className={s.wrapper}>
      <div className={!tipsOpened ? s.table : s.table_open}>
        <div className={!tipsOpened ? s.header : s.header_open}>
          <div className={s.header_title}>
            <button onClick={toggleTips} className={s.help}>
              <img src="/static/images/icons/help.svg" alt="" />
            </button>
            <div className={s.title}>{title}</div>
            <div className={s.number}>{(isAuth && tableData.userScore && tableData.userScore[0]) && `#${tableData.userScore[0]}`}
              {
                tipsOpened &&
                <sub>ваше место в рейтинге</sub>
              }

            </div>
          </div>
          {
            !tipsOpened &&
            <div className={s.columns}>
              {
                tableData.columnNames.map((name: string) => {
                  return (
                    <div key={name}>{name}</div>)
                })
              }
            </div>
          }
          {
            tipsOpened &&
            <p className={s.tipText}>{tableData.description}</p>
          }
        </div>
        {
          !tipsOpened &&
          <div className={s.body}>
            <div className={s.body_canvas}>
              {
                tableData.table.map(([place, vk, name, ...rest]: string[]) => (
                  <div key={vk}>
                    <div>{place}</div>
                    <div>
                      <Link to={`/${tableData.isTeamNomination ? "team" : "profile"}/${vk}`}>{name}</Link>
                    </div>
                    {rest.map((item, i) => <div key={i}>{item}</div>)}
                  </div>
                )
                )
              }
            </div>
            <div className={s.footer}>
              <div className={s.footer_canvas}>
                {!!tableData.userScore?.length &&
                  <div>
                    {tableData.userScore.map((item, i) => <div key={i}>{item}</div>)}
                  </div>
                }
                {<button onClick={() => showPopup(tableData.nominationId)}>Открыть весь список</button>}

              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { getNomination, openPopup })(Best);