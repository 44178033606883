import { AppDispatch, AppThunk } from "../store";
import { GET_TEAMS, GET_TOURNAMENT_TOP, LOADING_TEAMS } from "../types/actions";
import api from '../utils/api';

const getTeams = (id:number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({ 
        type: LOADING_TEAMS
    });
    const response = await api.get(`/Teams/Get/${ id }`);

    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_TEAMS,
            teams: result.data
        })
    };
};

const getTournamentTop = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch({ 
        type: LOADING_TEAMS
    });
    const response = await api.get(`/Teams/getTournamentTop`);

    const result = response.data;
    if (result.success) {
        dispatch({
            type: GET_TOURNAMENT_TOP,
            tournamentTops: result.data
        })
    };
};


export {
    getTournamentTop,
    getTeams
};